import axios from 'axios';

const httpClient = axios.create();

// adds access tokens in all api requests
// this interceptor is only added when the auth0 instance is ready
// and exports the getAccessTokenSilently method
export const addAccessTokenInterceptor = (getAccessTokenSilently) => {
  httpClient.interceptors.request.use(async (config) => {
    const newConfig = config;
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: window.globalConfig?.auth0?.audience,
        scope: 'openid profile email',
        prompt: 'consent',
      },
    });
    newConfig.headers.Authorization = `Bearer ${token}`;

    return newConfig;
  });
};

export default httpClient;
