// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React from 'react';
import PropTypes from 'prop-types';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material UI Components
// ───────────────────────────────────────────────────────────────────────────
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

// ───────────────────────────────────────────────────────────────────────────
// ─── Application Components
// ───────────────────────────────────────────────────────────────────────────
import useUserRoles from '../hooks/useUserRoles.jsx';
import PageLoading from './common/PageLoading.jsx';
import PhoneNumber from './PhoneNumber.jsx';

function EmployeeDetails({
  // Props
  employee,
  directoryCallIn,
}) {
  const { isAdmin } = useUserRoles();
  return (
    <>
      <center>
        <React.Suspense fallback={<PageLoading />}>
          <Avatar
            src={`/api/image/${employee.fileName || 'dummy'}`}
            alt={`A Portrait of ${employee.fullName}`}
            sx={{
              width: '50%',
              height: '50%',
            }}
            data-cy={`employee-portrait-${employee.email.split('@')[0]}`}
          />
        </React.Suspense>
      </center>
      {employee.title && (
        <Typography>
          <strong>Title:</strong> {employee.title}
        </Typography>
      )}
      {employee.email && (
        <Typography>
          <strong>Email: </strong>
          <Link data-cy={`${employee.email}-email`} href={`mailto:${employee.email}`}>
            {employee.email}
          </Link>
        </Typography>
      )}
      {employee.phone && (
        <Typography>
          <strong>Phone Number: </strong>
          <Link href={`tel:${employee.phone}`}>
            <PhoneNumber phone={employee.phone} />
          </Link>
        </Typography>
      )}
      {employee.extension && (
        <Typography>
          <strong>Extension: </strong>
          <Link href={`tel:${directoryCallIn},${employee.extension}`}>{employee.extension}</Link>
        </Typography>
      )}
      {employee.extension && (
        <Typography data-cy="direct-dial-employee">
          <strong>Direct: </strong>
          <PhoneNumber phone={directoryCallIn} />
        </Typography>
      )}
      {employee.manager && isAdmin && (
        <Typography data-cy={`manager-of-${employee.email}`}>
          <strong>Manager:</strong> {employee?.manager && employee?.managerName}
        </Typography>
      )}
    </>
  );
}

EmployeeDetails.propTypes = {
  employee: PropTypes.shape({
    department: PropTypes.string,
    email: PropTypes.string.isRequired,
    extension: PropTypes.string,
    fileName: PropTypes.string,
    fullName: PropTypes.string,
    manager: PropTypes.string,
    managerName: PropTypes.string,
    phone: PropTypes.string,
    title: PropTypes.string,
    id: PropTypes.string,
  }),
  directoryCallIn: PropTypes.string,
};

export default React.memo(EmployeeDetails);
