import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';

import AssignmentReturnRoundedIcon from '@mui/icons-material/AssignmentReturnRounded';
import DevicesIcon from '@mui/icons-material/Devices';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import FavoritesIcon from '@mui/icons-material/Star';

import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import axios from '../axiosClient.js';
import PageContext from '../contexts/PageContext.jsx';
import Alert from './common/Alert.jsx';
import NavButton from './common/NavButton.jsx';

const IT_REQUESTS_URL = 'https://tzmedical.atlassian.net/servicedesk/customer/portals';
const HR_DOCS_URL = 'https://drive.google.com/drive/folders/1yiuNfN0ZP1gZzm3rpT82c7A6KgNs2aLI';

function SideBar({
  // Props
  onClick,
}) {
  const { logout } = useAuth0();
  const { PageButtons } = React.useContext(PageContext);
  const [error, setError] = React.useState(null);
  const [version, setVersion] = React.useState('');

  const fetchVersion = React.useCallback(async () => {
    try {
      const versionNumber = await axios.get('/api/version');
      setVersion(versionNumber.data.version);
      setError(null);
    } catch (err) {
      setError(
        err.response?.data?.message ||
          err.response?.data ||
          err.message ||
          'Something bad happened...'
      );
    }
  }, []);

  React.useEffect(() => {
    fetchVersion();
  }, [fetchVersion]);

  return (
    <>
      <List data-cy="sidebar" onClick={onClick}>
        {!!PageButtons && (
          <>
            {PageButtons}
            <Divider />
          </>
        )}
        <NavButton
          Icon={<FavoritesIcon />}
          data-cy="nav-favorites"
          text="Favorites"
          to="/favorites"
        />
        <NavButton Icon={<PersonIcon />} data-cy="nav-employees" text="Employees" to="/employees" />
        <NavButton
          Icon={<DevicesIcon />}
          text="Help Desks"
          to={IT_REQUESTS_URL}
          data-cy="nav-it-requests"
        />
        <NavButton
          Icon={<InsertDriveFileIcon />}
          text="HR Documents"
          to={HR_DOCS_URL}
          data-cy="nav-hr-documents"
        />
        <NavButton
          Icon={<EditCalendarIcon />}
          data-cy="nav-request-business-lunch"
          text="Business Lunch Request"
          to="/business-lunch-request"
        />
        <NavButton
          Icon={<AssignmentReturnRoundedIcon />}
          text="Request Return Label"
          to="/request-return-label"
          data-cy="nav-request-return-label"
        />
        <NavButton Icon={<LogoutIcon />} text="Log out" onClick={logout} data-cy="nav-logout" />
      </List>
      <Typography
        data-cy="version-number"
        sx={{
          paddingLeft: 2,
          position: 'absolute',
          bottom: 0,
          paddingBottom: 1,
          opacity: 0.7,
        }}
      >
        v{version}
      </Typography>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />
    </>
  );
}

SideBar.propTypes = {
  onClick: PropTypes.func,
};

export default SideBar;
