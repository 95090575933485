// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React from 'react';
import PropTypes from 'prop-types';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Icons
// ───────────────────────────────────────────────────────────────────────────
import DeleteIcon from '@mui/icons-material/Delete';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

// ───────────────────────────────────────────────────────────────────────────
// ─── Contact List Components
// ───────────────────────────────────────────────────────────────────────────
import axios from '../../axiosClient.js';
import Alert from '../common/Alert.jsx';

function DeleteEmployee({ employee, setRefreshData }) {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [deleteText, setDeleteText] = React.useState('');

  const handleClose = React.useCallback(() => setOpen(false), []);
  const handleOpen = React.useCallback(() => setOpen(true), []);
  const handleInput = React.useCallback((event) => setDeleteText(event.target.value), []);
  const onSubmit = React.useCallback(async () => {
    try {
      await axios.delete(`/api/employee/${employee.email}`);
      setRefreshData(true);
    } catch (error) {
      setMessage(
        error.response?.data?.message ||
          error.response?.data?.error ||
          error.message ||
          'Something bad happened...'
      );
    }
  }, [employee.email, setRefreshData]);

  return (
    <>
      <IconButton
        aria-label="delete"
        color="secondary"
        data-cy={`delete-employee-${employee.email.split('@')[0]}`}
        onClick={handleOpen}
        onClose={handleClose}
      >
        <DeleteIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <Alert message={message} setMessage={setMessage} level="error" data-cy="error-message" />
        <DialogTitle>Delete {employee.fullName}</DialogTitle>
        <DialogContent>
          This will delete the user and remove them from view.
          <br />
          If you want to, type in &quot;DELETE&quot; and press the button
        </DialogContent>
        <TextField onChange={handleInput} label="DELETE" data-cy="delete-confirm-field" />
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} data-cy="cancel-delete">
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            disabled={deleteText !== 'DELETE'}
            onClick={onSubmit}
            data-cy="submit"
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DeleteEmployee;

DeleteEmployee.propTypes = {
  employee: PropTypes.shape({
    email: PropTypes.string,
    fullName: PropTypes.string,
  }),
  setRefreshData: PropTypes.func,
};
