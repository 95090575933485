/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/rules-of-hooks */
// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Icons
// ───────────────────────────────────────────────────────────────────────────
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import UploadDocumentIcon from '@mui/icons-material/UploadFile';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Components
// ───────────────────────────────────────────────────────────────────────────
import FormDocumentTheme from './FormDocumentTheme.jsx';

function FormDocumentInput({ control, name, label, id, rules }) {
  const { baseStyle, activeStyle, acceptStyle, rejectStyle } = FormDocumentTheme();
  const nameValidator = (document) => {
    if (rules?.pattern) {
      const pattern = rules.pattern.value || rules.pattern;
      const errorMessage = rules.pattern.message || '';
      if (!pattern.test(document.name)) {
        return {
          code: 'incorrect-suffix',
          message: errorMessage,
        };
      }
    }
    return null;
  };

  return (
    <Controller
      name={name}
      control={control}
      shouldUnregister
      rules={{
        required: !!rules?.required?.value,
      }}
      render={({ field: { onChange, value } }) => {
        const onDrop = React.useCallback(
          (acceptedDocuments) => {
            if (acceptedDocuments.length > 0) {
              onChange(acceptedDocuments[0]);
            } else {
              onChange(undefined);
            }
          },
          [onChange]
        );
        const {
          fileRejections,
          getRootProps,
          getInputProps,
          isDragActive,
          isDragAccept,
          isDragReject,
        } = useDropzone({
          maxDocuments: 1,
          validator: nameValidator,
          onDrop,
        });
        const style = React.useMemo(
          () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
          }),
          [isDragActive, isDragReject, isDragAccept]
        );

        return (
          <>
            <Grid item xs={12} sm={6}>
              <div {...getRootProps({ style })}>
                <input {...getInputProps({ onChange })} id={id} />
                <Box px={2} sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box px={2}>{label}</Box>
                  <Divider flexItem orientation="vertical" variant="middle" />
                  <Box px={2}>
                    <Button data-cy={`browse-${id}`} variant="contained">
                      Browse
                    </Button>
                  </Box>
                </Box>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              {value && (
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <UploadDocumentIcon color="secondary" />
                  &nbsp;{value.name}
                </Typography>
              )}
              {!value && rules?.required?.value && (
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <ErrorOutlineIcon color="error" />
                  &nbsp;{rules.required.message}
                </Typography>
              )}
              {fileRejections.length > 0 && (
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <ErrorOutlineIcon color="error" />
                  &nbsp;{fileRejections[0].errors[0].message}
                </Typography>
              )}
            </Grid>
          </>
        );
      }}
    />
  );
}

FormDocumentInput.propTypes = {
  /**
   * The [control](https://react-hook-form.com/api/useform/control)
   * object provided by invoking `useForm`.
   * Optional when using `FormProvider`.
   *
   * @example
   * ```js
   * import { useForm } from 'react-hook-form';
   * const { control, handleSubmit, getValues } = useForm();
   * ```
   * pass in control
   */
  control: PropTypes.object.isRequired,

  /**
   * Unique name of your input field
   */
  name: PropTypes.string.isRequired,

  /**
   * The label content
   */
  label: PropTypes.string.isRequired,

  /**
   * The id of your input field
   */
  id: PropTypes.string,

  /**
   * Validation rules in the same format for `register`,
   * which includes:
   * required, min, man, minLength, maxLength, pattern, validate
   *
   * @example
   * ```jsx
   * rules={{ required: true }}
   * ```
   */

  rules: PropTypes.object,
};

export default FormDocumentInput;
