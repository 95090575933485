// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React from 'react';
import PropTypes from 'prop-types';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material UI Components
// ───────────────────────────────────────────────────────────────────────────
import FavoriteIcon from '@mui/icons-material/Star';
import NotFavoriteIcon from '@mui/icons-material/StarBorder';

// ───────────────────────────────────────────────────────────────────────────
// ─── Contact List Contexts
// ───────────────────────────────────────────────────────────────────────────
import FavoritesContext from '../contexts/FavoritesContext.jsx';

function FavoriteEmployee({ id }) {
  const { addFavorite, removeFavorite, favorites } = React.useContext(FavoritesContext);
  const isFavorite = React.useMemo(() => favorites?.includes(id), [favorites, id]);

  const handleFavoritesToggle = React.useCallback(
    (event) => {
      if (isFavorite) {
        removeFavorite(id);
      } else {
        addFavorite(id);
      }
      event.stopPropagation();
    },
    [addFavorite, removeFavorite, id, isFavorite]
  );

  return isFavorite ? (
    <FavoriteIcon
      data-cy="remove-favorite-button"
      onClick={handleFavoritesToggle}
      sx={{ color: 'gold' }}
    />
  ) : (
    <NotFavoriteIcon
      data-cy="add-favorite-button"
      onClick={(event) => handleFavoritesToggle(event, id)}
    />
  );
}

export default FavoriteEmployee;

FavoriteEmployee.propTypes = {
  id: PropTypes.string,
};
