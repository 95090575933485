/* eslint-disable react/forbid-prop-types */
// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import Checkbox from '@mui/material/Checkbox';

function FormStringInput({
  control,
  name,
  label,
  id,
  defaultValue,
  required = false,
  disabled = false,
  rules,
  'data-cy': dataCy,
}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      shouldUnregister
      render={({ field }) => (
        <>
          <Checkbox
            checked={field.value}
            required={required}
            id={id}
            disabled={disabled}
            {...field}
            // This updates the form field value displayed to the user
            // so it's very important to immediately execute this, otherwise
            // the user won't see input, or will see delayed input as they type
            onChange={field.onChange}
            data-cy={dataCy || id}
          />
          {label}
        </>
      )}
      rules={rules}
    />
  );
}

FormStringInput.propTypes = {
  /**
   * The [control](https://react-hook-form.com/api/useform/control)
   * object provided by invoking `useForm`.
   * Optional when using `FormProvider`.
   *
   * @example
   * ```js
   * import { useForm } from 'react-hook-form';
   * const { control, handleSubmit, getValues } = useForm();
   * ```
   * pass in control
   */
  control: PropTypes.object.isRequired,

  /**
   * Unique name of your input field
   */
  name: PropTypes.string.isRequired,

  /**
   * The label content
   */
  label: PropTypes.string.isRequired,

  /**
   * The id of your input field
   */
  id: PropTypes.string,

  /**
   * **Important:** cannot apply undefined to `defaultValue`
   * * You need to either set `defaultValue` at the field-level
   *   or `useForm`'s `defaultValue`s.  Undefined is not a valid value.
   * * If your form will invoke `reset` with default values,
   *   you will need to provide `useForm` with `defaultValues`
   */
  defaultValue: PropTypes.bool,

  /**
   * If `true`, the label is displayed as required
   * and the input element is required.
   */
  required: PropTypes.bool,

  /**
   * If `true` the component is disabled
   */
  disabled: PropTypes.bool,

  /**
   * If `true` enables a select menu
   * Each item must be an object that contains
   * `id` and `name` properties
   */
  options: PropTypes.array,

  /**
   * Validation rules in the same format for `register`,
   * which includes:
   * required, min, man, minLength, maxLength, pattern, validate
   *
   * @example
   * ```jsx
   * rules={{ required: true }}
   * ```
   */
  rules: PropTypes.object,

  /**
   * A cypress supported `id`
   */
  'data-cy': PropTypes.string,
};

export default FormStringInput;
