// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Icons
// ───────────────────────────────────────────────────────────────────────────
import LaunchIcon from '@mui/icons-material/Launch';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material UI Components
// ───────────────────────────────────────────────────────────────────────────
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

function NavButton({
  // Props
  Icon,
  text,
  to,
  'data-cy': dataCy,
  ...buttonProps
}) {
  // Use location to highlight when our link matches the current page
  const location = useLocation();
  const external = to?.match(/^https:\/\//);

  return (
    <ListItemButton
      component={Link}
      to={to}
      target={external ? '_blank' : '_self'}
      selected={location.pathname === to}
      data-cy={dataCy}
      {...buttonProps}
    >
      <ListItemIcon sx={{ color: 'text.secondary' }}>{Icon}</ListItemIcon>
      <ListItemText primary={text} />
      {external && <LaunchIcon fontSize="small" />}
    </ListItemButton>
  );
}

export default NavButton;

NavButton.propTypes = {
  Icon: PropTypes.node,
  text: PropTypes.string,
  to: PropTypes.string,
  'data-cy': PropTypes.string,
};
