import React from 'react';
import PropTypes from 'prop-types';

const PageContext = React.createContext();

export function PageProvider({ children }) {
  const [PageButtons, setPageButtons] = React.useState(null);

  // Memoize the value to reduce rerenders
  const value = React.useMemo(() => ({ PageButtons, setPageButtons }), [PageButtons]);

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
}

PageProvider.propTypes = {
  children: PropTypes.node,
};

export default PageContext;
