import React from 'react';
import PropTypes from 'prop-types';

const EditorContext = React.createContext();

export function EditorContextProvider({ children }) {
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [employee, setEmployee] = React.useState({});

  const value = React.useMemo(
    () => ({
      isEditMode,
      setIsEditMode,
      isOpen,
      setIsOpen,
      employee,
      setEmployee,
    }),
    [isEditMode, setIsEditMode, isOpen, setIsOpen, employee, setEmployee]
  );

  return <EditorContext.Provider value={value}>{children}</EditorContext.Provider>;
}

export default EditorContext;

EditorContextProvider.propTypes = {
  children: PropTypes.node,
};
