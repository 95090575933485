import React from 'react';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import UhOhRobot from '../assets/404-robot.svg';

//---------------------------------------------------------------------------
// Render
//---------------------------------------------------------------------------
function Page404() {
  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          m: 3,
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          // 85vh subjectively looks better than 100vh on large screens
          height: 'calc(85vh - 80px)',
        }}
      >
        <Grid
          container
          data-cy="not-found-message"
          spacing={12}
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12} sm={6}>
            <h1>404 Page Not Found</h1>
            <Typography variant="subtitle1">Sorry, an unexpected error has occurred.</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <img src={UhOhRobot} alt="404" />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default Page404;
