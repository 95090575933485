/* eslint-disable react-hooks/rules-of-hooks */
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

const FormDocumentTheme = () => {
  const theme = useTheme();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: prefersDarkMode ? '#bababa' : '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: prefersDarkMode ? '#353535' : '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  };
  const activeStyle = {
    borderColor: '#2196f3',
  };
  const acceptStyle = {
    borderColor: theme.palette.secondary.main,
  };
  const rejectStyle = {
    borderColor: '#ff1744',
  };
  return { baseStyle, activeStyle, acceptStyle, rejectStyle };
};

export default FormDocumentTheme;
