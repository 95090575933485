// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React from 'react';
import PropTypes from 'prop-types';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material UI Icons
// ───────────────────────────────────────────────────────────────────────────
import North from '@mui/icons-material/North';
import RemoveIcon from '@mui/icons-material/Remove';
import South from '@mui/icons-material/South';

function SortDirectionIcon({ reverse, selected }) {
  const color = selected ? 'action' : 'disabled';
  let icon;

  // Replaces up or down arrow with horizontal line when not selected.
  if (reverse === null || !selected) {
    icon = <RemoveIcon color={color} />;
  } else if (reverse) {
    icon = <South color={color} />;
  } else {
    icon = <North color={color} />;
  }
  return icon;
}

SortDirectionIcon.propTypes = {
  reverse: PropTypes.bool,
  selected: PropTypes.bool,
};

export default SortDirectionIcon;
