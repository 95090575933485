import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';

import PageLoading from './components/common/PageLoading.jsx';
import ExternalLinkRedirect from './components/ExternalLinkRedirect.jsx';
import Layout from './components/Layout.jsx';
import useCustomTheme from './hooks/customizeReactTheme.jsx';
import EmbedGoogleForm from './pages/EmbedGoogleForm.jsx';
import EmployeeViewer from './pages/EmployeeViewer.jsx';
import Page404 from './pages/Page404.jsx';
import { addAccessTokenInterceptor } from './axiosClient.js';

const RETURN_LABEL_URL =
  'https://fulfillment.fedex.com/web/commerce/rma-web?customerId=2558242&token=eyJjdHkiOiJKV1QiLCJlbmMiOiJBMTI4Q0JDLUhTMjU2IiwiYWxnIjoiZGlyIn0..NHe9pVrR0387WCSUF4OAUw.3dF1Jd_JKW8ni-6iTxSj2OrcBSxVXZr40lgOw8gKtG7YWoc_M5046fAoUqTd3l33p7ECUrJsWmCPYcE12ljqLpvA3razT7YmACE3NRS_ntTsoF9x3elVgaFnlEa8Yf9bygrrjQKnh_SUerR4o7B5so_JgJ6uBF0FbIrfkvDgMPB2qTyLAXMEhCS7t74PpdPZ-BsymtKHMOQaGXTb9T1Z_-xNH2llCa8NsqgQo0TSWMfkpzDmR-Mb54M_-ya8z8AG1KqUXQwAsFWsD5OMSiwxpSmQ6bxg233agOvxB-_qIgYvab_FoipmuPD3osWzxp-LSCymG5yIAIc1GSvTLxDynPLnocExY_MJdHp184geiaYOs1s0U2KJoPYxwMDqd3Hm.aslFF32Q5X5Z-4jqUsVYaw';

function App() {
  const { error, getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect } =
    useAuth0();

  const theme = useCustomTheme();

  React.useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        appState: {
          returnTo: window.location.pathname,
        },
      });
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  React.useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  if (error && (isLoading || !isAuthenticated)) {
    return <CircularProgress variant="soft" />;
  }

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        {error && (
          <Container maxWidth="lg">
            <div>Oops... {error.message}</div>
            <br />
            <Button
              variant="contained"
              onClick={() =>
                loginWithRedirect({
                  appState: { returnTo: window.location.origin },
                })
              }
            >
              Login
            </Button>
          </Container>
        )}
        {!error && (isLoading || !isAuthenticated) && <PageLoading />}
        {isAuthenticated && (
          <Layout>
            <Routes>
              <Route index path="/" element={<Navigate to="/employees" replace />} />
              <Route path="/favorites" element={<EmployeeViewer onlyFavorites />} />
              <Route path="/employees" element={<EmployeeViewer />} />
              <Route
                path="/business-lunch-request"
                element={
                  <EmbedGoogleForm
                    title="Business Lunch Request"
                    formSrc="https://forms.gle/VGKTMexgFFHuubQu7"
                  />
                }
              />
              <Route
                path="/request-return-label"
                element={<ExternalLinkRedirect url={RETURN_LABEL_URL} />}
              />
              <Route path="*" element={<Page404 />} />
            </Routes>
          </Layout>
        )}
      </ThemeProvider>
    </div>
  );
}

export default App;
