// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React from 'react';
import PropTypes from 'prop-types';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material UI Components
// ───────────────────────────────────────────────────────────────────────────
import Box from '@mui/material/Box';

// -----
function EmbedGoogleForm({ title, formSrc }) {
  return (
    // 80 px is the height of the AppBar component
    <Box height="calc(100vh - 80px)" overflow="hidden">
      <iframe
        title={title}
        src={formSrc}
        width="100%"
        height="100%"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
      >
        Loading…
      </iframe>
    </Box>
  );
}

EmbedGoogleForm.propTypes = {
  title: PropTypes.string.isRequired,
  formSrc: PropTypes.string.isRequired,
};

export default EmbedGoogleForm;
