import React from 'react';
import PropTypes from 'prop-types';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Icons
// ───────────────────────────────────────────────────────────────────────────
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import Close from '@mui/icons-material/Close';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import Info from '@mui/icons-material/Info';
import WarningAmber from '@mui/icons-material/WarningAmber';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import ReactAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';

function Alert({
  // Props
  title,
  message,
  setMessage,
  level,
  variant,
  'data-cy': dataCy,
  allowClose = true,
}) {
  if (variant === 'dialog') {
    const dialogTitle = {
      error: (
        <>
          <ErrorOutline color="error" />
          &nbsp;&nbsp;{title || 'Error'}
        </>
      ),
      warning: (
        <>
          <WarningAmber color="warning" />
          &nbsp;&nbsp;{title || 'Warning'}
        </>
      ),
      info: (
        <>
          <Info color="info" />
          &nbsp;&nbsp;{title || 'Info'}
        </>
      ),
      success: (
        <>
          <CheckCircleOutline color="success" />
          &nbsp;&nbsp;{title || 'Success'}
        </>
      ),
    };

    return (
      <Dialog open={!!message} maxWidth="sm" data-cy={dataCy} fullWidth>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          {dialogTitle[level]}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        {allowClose && (
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setMessage(null);
              }}
              autoFocus
              data-cy="close-alert"
            >
              Okay
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  }
  if (variant === 'snackbar') {
    return (
      <Snackbar
        open={!!message}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{ bottom: { xs: 90, sm: 0 }, maxWidth: 'lg' }}
      >
        <ReactAlert
          severity={level}
          variant="filled"
          data-cy={dataCy}
          action={
            allowClose && (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setMessage(null);
                }}
                data-cy="close-alert"
              >
                <Close fontSize="inherit" />
              </IconButton>
            )
          }
          sx={{ mb: 2 }}
        >
          {message}
        </ReactAlert>
      </Snackbar>
    );
  }
  return (
    <Box sx={{ width: '100%' }}>
      <Collapse in={!!message}>
        <ReactAlert
          severity={level}
          data-cy={dataCy}
          action={
            allowClose && (
              <IconButton
                aria-label="close"
                color={level === 'info' ? 'secondary' : 'inherit'}
                size="small"
                onClick={() => {
                  setMessage(null);
                }}
                data-cy="close-alert"
              >
                <Close fontSize="inherit" />
              </IconButton>
            )
          }
          sx={{ ...(level === 'info' && allowClose && { backgroundColor: 'white' }) }}
        >
          {message}
        </ReactAlert>
      </Collapse>
    </Box>
  );
}

Alert.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  setMessage: PropTypes.func,
  level: PropTypes.string,
  variant: PropTypes.string,
  allowClose: PropTypes.bool,
  'data-cy': PropTypes.string,
};

export default Alert;
