// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React from 'react';
import PropTypes from 'prop-types';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material UI Icons
// ───────────────────────────────────────────────────────────────────────────
import Menu from '@mui/icons-material/Menu';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material UI Components
// ───────────────────────────────────────────────────────────────────────────
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

// ───────────────────────────────────────────────────────────────────────────
// Application Components
// ───────────────────────────────────────────────────────────────────────────
import ProfileDropdown from './ProfileDropdown.jsx';
import SearchBar from './SearchBar.jsx';

function TopBar({
  // Props
  onClick,
  onClose,
}) {
  const ultraNarrow = useMediaQuery('(max-width:369px)');

  return (
    <AppBar
      position="fixed"
      sx={{ marginTop: 0, boxShadow: 'none', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      data-cy="navbar"
    >
      <Toolbar>
        <Grid container spacing={3} justifyContent="space-between" alignItems="center">
          <Grid item xs="auto">
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={onClick}
            >
              <Menu />
            </IconButton>
          </Grid>
          <Grid item xs="auto" sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Grid container alignItems="flex-start">
              <Grid item>
                <img src="/favicon.ico" alt="TZ" height="32px" />
              </Grid>
              <Grid>
                <Typography variant="h6" noWrap component="div" sx={{ ml: '10px' }}>
                  Connect
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs onClick={onClose}>
            <SearchBar />
          </Grid>
          {!ultraNarrow && (
            <Grid item xs="auto">
              <ProfileDropdown />
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;

TopBar.propTypes = {
  onClick: PropTypes.func,
  onClose: PropTypes.func,
};
