// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React from 'react';
import PropTypes from 'prop-types';

const FavoritesContext = React.createContext();

export function FavoritesContextProvider({ children }) {
  const [favorites, setFavorites] = React.useState([]);

  const addFavorite = React.useCallback(
    (id) => {
      if (!favorites.includes(id)) {
        const newFavorites = favorites.concat([id]);

        setFavorites(newFavorites);
        localStorage.setItem('favorites', JSON.stringify(newFavorites));
      }
    },
    [favorites]
  );

  const removeFavorite = React.useCallback(
    (id) => {
      const index = favorites.indexOf(id);

      // If it exists...
      if (index > -1) {
        // remove it...
        favorites.splice(index, 1);

        setFavorites([...favorites]);
        localStorage.setItem('favorites', JSON.stringify(favorites));
      }
    },
    [favorites]
  );

  // Initialize local storage...
  React.useEffect(() => {
    const storedEmployees = localStorage.getItem('favorites');

    try {
      const parsedEmployees = JSON.parse(storedEmployees);

      if (Array.isArray(parsedEmployees)) {
        setFavorites(parsedEmployees);
      } else {
        setFavorites([]);
      }
    } catch {
      // Parsing failed...
      setFavorites([]);

      // Fix badly formatted employee array...
      localStorage.setItem('favorites', JSON.stringify([]));
    }
  }, []);

  const value = React.useMemo(
    () => ({
      addFavorite,
      removeFavorite,
      favorites,
    }),
    [addFavorite, removeFavorite, favorites]
  );

  return <FavoritesContext.Provider value={value}>{children}</FavoritesContext.Provider>;
}

export default FavoritesContext;

FavoritesContextProvider.propTypes = {
  children: PropTypes.node,
};
