import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { useInterval } from '@tzmedical/react-hooks';

const AUTOMATIC_REFRESH_INTERVAL_MS = 60 * 60 * 1000;
const ROLES_CLAIM_KEY = 'https://tzmedical.io/roles';

function useUserRoles() {
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [userRoles, setUserRoles] = React.useState([]);
  const { getIdTokenClaims, isAuthenticated } = useAuth0();
  const memoizedReturn = React.useMemo(() => ({ isAdmin, userRoles }), [isAdmin, userRoles]);

  const getRoles = React.useCallback(async () => {
    if (isAuthenticated) {
      const claims = await getIdTokenClaims();
      if (claims) {
        const auth0Roles = claims[ROLES_CLAIM_KEY] || [];
        setUserRoles(auth0Roles);
        setIsAdmin(auth0Roles.includes('Contact Admin'));
      }
    }
  }, [getIdTokenClaims, isAuthenticated]);

  useInterval(getRoles, AUTOMATIC_REFRESH_INTERVAL_MS, isAuthenticated);

  return memoizedReturn;
}

export default useUserRoles;
