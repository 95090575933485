// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React from 'react';
import PropTypes from 'prop-types';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material UI Icons
// ───────────────────────────────────────────────────────────────────────────
import Phone from '@mui/icons-material/Phone';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material UI Components
// ───────────────────────────────────────────────────────────────────────────
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import PhoneNumber from './PhoneNumber.jsx';

function PhoneIconWithHref({ href }) {
  const handleClick = React.useCallback((event) => {
    event.stopPropagation();
  }, []);
  return (
    <IconButton href={href} onClick={handleClick}>
      <Phone color="secondary" />
    </IconButton>
  );
}

PhoneIconWithHref.propTypes = {
  href: PropTypes.string.isRequired,
};

function PhoneIconWithModal({ officeNumber, extension, phone }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const extensionHref = `tel:${officeNumber},${extension}`;
  const phoneHref = `tel:${phone}`;

  // Stops the user from interacting with the environment behind the modal.
  const handleClick = React.useCallback(
    (event) => {
      // prevents event from bubbling up so the parent
      // element (the accordion) doesn't get its `onClick` function triggered.
      event.stopPropagation();
      setIsOpen((previous) => !previous);
    },
    [setIsOpen]
  );

  const handleClose = React.useCallback(
    (event) => {
      setIsOpen(false);
      event.stopPropagation();
    },
    [setIsOpen]
  );

  return (
    <>
      <IconButton onClick={handleClick}>
        <Phone color="secondary" />
      </IconButton>
      <Dialog open={isOpen} onClose={handleClose} fullWidth>
        <DialogContent>
          <Link href={phoneHref} sx={{ display: 'block' }}>
            <Grid container direction="row" alignItems="center">
              <Grid item xs={2}>
                <Phone />
              </Grid>
              <Grid item xs={8}>
                <Typography>
                  <PhoneNumber phone={phone} />
                </Typography>
              </Grid>
            </Grid>
          </Link>
          <Link href={extensionHref} sx={{ display: 'block' }}>
            <Grid container direction="row" alignItems="center">
              <Grid item xs={2}>
                <Phone />
              </Grid>
              <Grid item xs={8}>
                <Typography>{`TZ# ${extension}`}</Typography>
              </Grid>
            </Grid>
          </Link>
        </DialogContent>
      </Dialog>
    </>
  );
}

PhoneIconWithModal.propTypes = {
  officeNumber: PropTypes.string.isRequired,
  extension: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
};

function EmployeePhoneButton({ officeNumber, extension, phone }) {
  // If both exist.
  if (extension && phone) {
    return <PhoneIconWithModal officeNumber={officeNumber} extension={extension} phone={phone} />;
  }
  if (extension) {
    return <PhoneIconWithHref href={`tel:${officeNumber},${extension}`} />;
  }
  if (phone) {
    return <PhoneIconWithHref href={`tel:${phone}`} />;
  }
  return (
    <IconButton disabled>
      <Phone />
    </IconButton>
  );
}

export default React.memo(EmployeePhoneButton);

EmployeePhoneButton.propTypes = {
  officeNumber: PropTypes.string,
  extension: PropTypes.string,
  phone: PropTypes.string,
};
