import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import PageLoading from './common/PageLoading.jsx';

function ExternalLinkRedirect({ url }) {
  const navigate = useNavigate();

  React.useEffect(() => {
    window.location.href = url;
  }, [url, navigate]);

  return <PageLoading />;
}

ExternalLinkRedirect.propTypes = {
  url: PropTypes.string.isRequired,
};

export default ExternalLinkRedirect;
