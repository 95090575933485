// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import PropTypes from 'prop-types';

// ───────────────────────────────────────────────────────────────────────────
// ─── Font Imports
// ───────────────────────────────────────────────────────────────────────────
import '@fontsource/public-sans';

// ───────────────────────────────────────────────────────────────────────────
// ─── Contact List Components
// ───────────────────────────────────────────────────────────────────────────
import App from './App.jsx';
import * as serviceWorkerRegistration from './serviceWorkerRegistration.js';

function Auth0ProviderWithRedirectCallback({ children, ...props }) {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    // after login return to the previous page
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
}
const { audience, cacheLocation, clientId, domain } = window.globalConfig.auth0;
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithRedirectCallback
        domain={domain}
        clientId={clientId}
        cacheLocation={cacheLocation}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience,
          scope: 'openid profile email',
        }}
      >
        <App />
      </Auth0ProviderWithRedirectCallback>
    </BrowserRouter>
  </React.StrictMode>
);

// allows app to work offline with cached api calls,
// learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

Auth0ProviderWithRedirectCallback.propTypes = {
  children: PropTypes.node.isRequired,
};
