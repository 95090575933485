// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Icons
// ───────────────────────────────────────────────────────────────────────────
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material UI Components
// ───────────────────────────────────────────────────────────────────────────
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

// ───────────────────────────────────────────────────────────────────────────
// ─── TZ Components
// ───────────────────────────────────────────────────────────────────────────
import { useFilter, useInterval, useSort } from '@tzmedical/react-hooks';

// ───────────────────────────────────────────────────────────────────────────
// ─── Contact List Components
// ───────────────────────────────────────────────────────────────────────────
import axios from '../axiosClient.js';
import EmployeeForm from '../components/actions/EmployeeForm.jsx';
import Alert from '../components/common/Alert.jsx';
import PageLoading from '../components/common/PageLoading.jsx';
import EmployeeListItem from '../components/EmployeeListItem.jsx';
import SortSelector from '../components/SortSelector.jsx';
import FavoritesContext from '../contexts/FavoritesContext.jsx';
import PageContext from '../contexts/PageContext.jsx';
import SearchContext from '../contexts/SearchContext.jsx';
import useUserRoles from '../hooks/useUserRoles.jsx';

// ───────────────────────────────────────────────────────────────────────────
// ─── Variables
// ───────────────────────────────────────────────────────────────────────────
const ROLES_CLAIM_KEY = 'https://tzmedical.io/roles';
const AUTOMATIC_REFRESH_INTERVAL_MS = 60 * 60 * 1000;

const sortOptions = {
  defaultSort: [
    { field: 'department', reverse: false },
    { field: 'fullName', reverse: false },
  ],
};

function EmployeeViewer({ onlyFavorites }) {
  const { isAdmin } = useUserRoles();
  const [pageLoading, setPageLoading] = React.useState(true);
  const [employeeList, setEmployeeList] = React.useState([]);
  const [error, setError] = React.useState(null);
  const { getIdTokenClaims, isAuthenticated } = useAuth0();

  const searchFields = React.useMemo(() => {
    const baseFields = {
      name: 'fullName',
      department: 'department',
      title: 'title',
      email: 'email',
      ext: 'extension',
      phone: 'phone',
      manager: 'managerName',
    };

    const adminFields = isAdmin ? { has: { image: (item) => !!item.fileName } } : {};
    return { ...baseFields, ...adminFields };
  }, [isAdmin]);

  const searchHelper = React.useMemo(
    () => [
      { label: 'Has the words', keyword: '+', variant: 'global' },
      { label: "Doesn't have", keyword: '-', variant: 'global' },
      { label: 'Department', keyword: 'department', variant: 'negatable' },
      { label: 'Title', keyword: 'title', variant: 'negatable' },
      { label: 'Email', keyword: 'email', variant: 'negatable' },
      { label: 'Manager', keyword: 'manager', variant: 'negatable' },
      ...(isAdmin ? [{ label: 'Has Image', keyword: 'has:image', variant: 'toggle' }] : []),
    ],
    [isAdmin]
  );

  const { favorites } = React.useContext(FavoritesContext);

  const visibleEmployees = React.useMemo(() => {
    if (onlyFavorites) {
      return employeeList.filter((employee) => favorites.includes(employee.id));
    }
    return employeeList;
  }, [employeeList, onlyFavorites, favorites]);

  //---------------------------------------------------------------------------
  // Page data
  //---------------------------------------------------------------------------
  const forceRefresh = React.useMemo(
    () => pageLoading && isAuthenticated,
    [isAuthenticated, pageLoading]
  );
  const fetchEmployees = React.useCallback(async () => {
    if (isAuthenticated) {
      try {
        if (!error) {
          const { data } = await axios.get(`/api/user`);
          const employeeData = data.map((employee) => {
            const manager = data.find(({ email }) => email === employee?.manager);

            return {
              ...employee,
              managerName: `${manager?.firstName} ${manager?.lastName}`,
            };
          });

          // Set the new employees with the manager name
          setEmployeeList(employeeData);
          setError(null);
          setPageLoading(false);
        }
      } catch (err) {
        setError(
          err.response?.data?.message ||
            err.response?.data ||
            err.message ||
            'Something bad happened...'
        );
      }
    }
  }, [error, isAuthenticated]);

  useInterval(fetchEmployees, AUTOMATIC_REFRESH_INTERVAL_MS, forceRefresh);

  //---------------------------------------------------------------------------
  // Search and sort
  //---------------------------------------------------------------------------
  const { search, setSearchDecorator, setSearchHelper } = React.useContext(SearchContext);

  const filteredEmployees = useFilter(visibleEmployees, search, searchFields);
  const [sortedEmployees, handleSortSelection, currentSort] = useSort(
    filteredEmployees,
    sortOptions
  );
  React.useEffect(() => {
    setSearchHelper(searchHelper);
    setSearchDecorator(<SortSelector setSort={handleSortSelection} sort={currentSort} />);
  }, [currentSort, handleSortSelection, searchHelper, setSearchDecorator, setSearchHelper]);

  //---------------------------------------------------------------------------
  // Permissions handling
  //---------------------------------------------------------------------------
  const [userRoles, setUserRoles] = React.useState([]);
  const getRoles = React.useCallback(async () => {
    const claims = await getIdTokenClaims();
    setUserRoles(claims[ROLES_CLAIM_KEY]);
  }, [setUserRoles, getIdTokenClaims]);
  useInterval(getRoles, AUTOMATIC_REFRESH_INTERVAL_MS, isAuthenticated);

  //---------------------------------------------------------------------------
  // Edit mode
  //---------------------------------------------------------------------------
  const [edit, setEdit] = React.useState(false);
  const toggleEdit = React.useCallback(() => setEdit((prev) => !prev), []);

  //---------------------------------------------------------------------------
  // Navbar button
  //---------------------------------------------------------------------------
  const { setPageButtons } = React.useContext(PageContext);
  React.useEffect(() => {
    if (userRoles.includes('Contact Admin')) {
      setPageButtons(
        <>
          <ListItem>
            <EmployeeForm setRefreshData={setPageLoading} employeeList={employeeList} />
          </ListItem>
          <ListItem sx={{ mb: 1 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={toggleEdit}
              fullWidth
              data-cy={edit ? 'disable-editing' : 'enable-editing'}
              startIcon={edit ? <CancelIcon /> : <EditIcon />}
            >
              {edit ? 'Disable Editing' : 'Enable Editing'}
            </Button>
          </ListItem>
        </>
      );
    }
    return () => setPageButtons(null);
  }, [edit, employeeList, setPageButtons, toggleEdit, userRoles]);

  const mobile = !useMediaQuery('(min-width: 600px)');
  const forceExpand = React.useMemo(
    () => sortedEmployees.length === 1 || !mobile,
    [mobile, sortedEmployees.length]
  );

  if (onlyFavorites && favorites.length === 0) {
    return (
      <center>
        <Typography variant="body1">
          Please &apos;favorite&apos; a user from the <Link to="/employees">employee page!</Link>
        </Typography>
      </center>
    );
  }

  return (
    <main>
      <Alert message={error} setMessage={setError} level="error" />
      {pageLoading && !error && <PageLoading />}
      {!pageLoading && (
        <Grid container justifyContent="center">
          <Grid item xs={12} md={10}>
            <Grid container display="flex" sx={{ flexGrow: 1 }} data-cy="employee-data" spacing={1}>
              {sortedEmployees?.map((employee) => (
                <Grid item xs={12} sm={6} md={4} key={employee.id}>
                  <EmployeeListItem
                    employee={employee}
                    employeeList={employeeList}
                    forceExpand={forceExpand}
                    setRefreshData={setPageLoading}
                    allowEdit={userRoles.includes('Contact Admin') && edit}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
    </main>
  );
}

export default EmployeeViewer;

EmployeeViewer.propTypes = {
  onlyFavorites: PropTypes.bool,
};
