// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React from 'react';
import PropTypes from 'prop-types';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material UI Icons
// ───────────────────────────────────────────────────────────────────────────
import Sort from '@mui/icons-material/Sort';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material UI Components
// ───────────────────────────────────────────────────────────────────────────
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useTheme from '@mui/material/styles/useTheme';

// ───────────────────────────────────────────────────────────────────────────
// ─── Contact List Components
// ───────────────────────────────────────────────────────────────────────────
import SortDirectionIcon from './SortDirectionIcon.jsx';

const options = {
  fullName: 'Name',
  department: 'Department',
  title: 'Title',
  email: 'Email',
  phone: 'Phone Number',
  extension: 'Extension',
  isManager: 'Manager',
};

function SortSelector({ setSort, sort }) {
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const handleToggle = React.useCallback(() => {
    setOpen((prev) => !prev);
  }, [setOpen]);

  const iconRef = React.useRef(null);

  const selectHandler = React.useCallback(
    (fieldName) => {
      setSort(fieldName);
      handleToggle();
    },
    [handleToggle, setSort]
  );

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <IconButton onClick={handleToggle} size="small" sx={{ color: theme.palette.common.white }}>
        <Sort ref={iconRef} />
      </IconButton>
      <Menu
        open={open}
        anchorEl={iconRef.current}
        data-cy="sort-menu"
        TransitionComponent={Fade}
        onClose={handleClose}
      >
        {Object.entries(options).map(([fieldName, displayName]) => (
          <MenuItem
            data-cy={`${fieldName}-sort`}
            key={fieldName}
            value={fieldName}
            onClick={() => {
              selectHandler(fieldName);
            }}
          >
            <SortDirectionIcon reverse={sort?.reverse} selected={fieldName === sort?.field} />
            {displayName}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default SortSelector;

SortSelector.propTypes = {
  setSort: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  sort: PropTypes.object,
};
