import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

function AcknowledgmentPopup() {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (!localStorage.getItem('has-seen-tz-connect-warning')) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, []);

  const handleClose = React.useCallback(() => {
    setOpen(false);
    localStorage.setItem('has-seen-tz-connect-warning', 'TRUE');
  }, []);

  return (
    <Dialog open={open}>
      <DialogContent>
        <DialogContentText>
          Outside representative&#39;s cell phone numbers should be shared with medical facilities
          only.
        </DialogContentText>
        <DialogContentText>
          All other cell phone numbers are for internal use only.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Acknowledge</Button>
      </DialogActions>
    </Dialog>
  );
}

export default AcknowledgmentPopup;
