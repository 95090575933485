import React from 'react';
import PropTypes from 'prop-types';

function PhoneNumber({ phone }) {
  // Match and format phone numbers in the format 503-123-4567
  if (/^\d{3}-\d{3}-\d{4}$/.test(phone)) {
    return phone.replace(/(\d{3})-(\d{3})-(\d{4})/, '($1) $2-$3');
  }

  // Match and format phone numbers in the format +19712504238
  if (/^\+\d{11}$/.test(phone)) {
    return phone.replace(/^\+1(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
  }

  // return phone if neither number matches
  return phone;
}

// Memoize, since this is a stateless component
export default React.memo(PhoneNumber);

PhoneNumber.propTypes = {
  phone: PropTypes.string.isRequired,
};
